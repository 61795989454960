/* eslint-disable global-require */
import globModules from './glob-modules';

const reactModule = (Component, nodeList) => {
  const React = require('react');
  const { render } = require('react-dom');

  nodeList.forEach(node => render(<Component {...node.dataset} />, node));
};

const components = [
  {
    name: 'LogoCarousel',
    loader: () => import('./components/LogoCarousel')
  },
  {
    name: 'VideoModal',
    loader: () => import('./components/VideoModal')
  },
  {
    name: 'ShareThis',
    loader: () => import('./components/ShareThis')
  },
  {
    name: 'Modal',
    loader: () => import('./components/Modal')
  }
];

const modules = [
  { name: 'Header', loader: () => import('./modules/header/index') },
  {
    name: 'FullWidthCtaBanner',
    loader: () => import('./modules/FullWidthCtaBanner')
  },
  {
    name: 'PartnerLogoBanner',
    loader: () => import('./modules/PartnerLogoBanner')
  },
  {
    name: 'ContentWithMediaRow',
    loader: () => import('./modules/ContentWithMediaRow')
  },
  {
    name: 'ContentSideNavigation',
    loader: () => import('./modules/ContentSideNavigation')
  },
  {
    name: 'FilteredResourceCards',
    loader: () => import('./modules/FilteredResourceCards')
  },
  {
    name: 'IconGrid',
    loader: () => import('./modules/IconGrid')
  },
  {
    name: 'HomepageMasthead',
    loader: () => import('./modules/HomepageMasthead')
  },
  {
    name: 'RecommendedContent',
    loader: () => import('./modules/RecommendedContent')
  },
  {
    name: 'FeaturedResourceCards',
    loader: () => import('./modules/FeaturedResourceCards')
  },
  {
    name: 'TestimonialsRow',
    loader: () => import('./modules/TestimonialsRow')
  },
  {
    name: 'FeaturedContentTabsRow',
    loader: () => import('./modules/FeaturedContentTabsRow')
  },
  {
    name: 'FeaturedContentTabsWithImageRow',
    loader: () => import('./modules/FeaturedContentTabsWithImageRow')
  },
  {
    name: 'MediaMasthead',
    loader: () => import('./modules/MediaMasthead')
  },
  {
    name: 'FeaturedResourceCardsWithImage',
    loader: () => import('./modules/FeaturedResourceCardsWithImage')
  },
  {
    name: 'SimpleMasthead',
    loader: () => import('./modules/SimpleMasthead')
  },
  {
    name: 'Footer',
    loader: () => import('./modules/Footer')
  },
  {
    name: 'PortalLogin',
    loader: () => import('./modules/PortalLogin')
  },
  {
    name: 'ProductCheckout',
    loader: () => import('./modules/ProductCheckout')
  },
  {
    name: 'AlertBanner',
    loader: () => import('./modules/AlertBanner')
  },
  {
    name: 'Accordion',
    loader: () => import('./modules/Accordion')
  },
  {
    name: 'SearchResultsListing',
    loader: () => import('./modules/react/SearchResultsListing'),
    render: reactModule
  },
  {
    name: 'DriverDownloadBlock',
    loader: () => import('./modules/react/DriverDownloadBlock'),
    render: reactModule
  },
  {
    name: 'ProductComparisonTable',
    loader: () => import('./modules/ProductComparisonTable')
  },
  {
    name: 'ContactUsForm',
    loader: () => import('./modules/ContactUsForm')
  },
  {
    name: 'LoginModal',
    loader: () => import('./modules/LoginModal')
  },
  {
    name: 'SendProfile',
    loader: () => import('./modules/SendProfile')
  },
  {
    name: 'LocationMap',
    loader: () => import('./modules/LocationMap')
  },
  {
    name: 'AppointmentForm',
    loader: () => import('./modules/AppointmentForm')
  },
  {
    name: 'YourAppointments',
    loader: () => import('./modules/YourAppointments')
  },
  { name: 'ModalProfiler', loader: () => import('./modules/ModalProfiler') },
  { name: 'CheckIn', loader: () => import('./modules/CheckIn') },
  { name: 'ShowRecord', loader: () => import('./modules/ShowRecord') },
  { name: 'ReferralsForm', loader: () => import('./modules/ReferralsForm') },
  { name: 'ProfileTable', loader: () => import('./modules/ProfileTable') },
  {
    name: 'ThankPage',
    loader: () => import('./modules/ThankPage')
  }
];

export default [...globModules, ...components, ...modules];

const isAnchorInternal = anchor =>
  !anchor.hostname ||
  anchor.hostname === window.location.hostname ||
  anchor.hostname.indexOf('smartling') > -1 ||
  anchor.hostname.indexOf('episerver') > -1 ||
  anchor.hostname.indexOf('loftware') > -1 ||
  anchor.classList.contains('btn');

const checkExternalLinks = () => {
  document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('a').forEach(anchor => {
      if (!isAnchorInternal(anchor)) {
        if (anchor.children.length === 0) {
          // const span = document.createElement('span');
          // span.innerHTML = anchor.innerHTML;
          // anchor.innerHTML = '';
          // anchor.appendChild(span);

          anchor.classList.add('external-link');
        }
      }
    });
  });
};

export default checkExternalLinks;
